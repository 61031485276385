import React from "react";
import Box from '@material-ui/core/Box';


function MenuIcon(props) {
  return (
    <Box  {...props} >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#201F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 18H3M21 14H3M21 10H3M21 6H3"
      ></path>
    </svg>
   </Box>  
  );
}

export default MenuIcon;
