import React from "react";

function LikeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#201F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.064 17H2.626a1.64 1.64 0 01-1.15-.469A1.587 1.587 0 011 15.4V9.8c0-.424.171-.831.476-1.131a1.64 1.64 0 011.15-.47h2.438m5.69-1.599V3.4a2.38 2.38 0 00-.713-1.697A2.459 2.459 0 008.316 1L5.064 8.2V17h9.17c.392.004.773-.131 1.071-.38.3-.25.496-.599.555-.98l1.122-7.2a1.575 1.575 0 00-.381-1.29 1.624 1.624 0 00-1.245-.55h-4.601z"
      ></path>
    </svg>
  );
}

export default LikeIcon;
