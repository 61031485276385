import React from "react";
import Box from '@material-ui/core/Box';



function FacebookIcon(props) {
  return (
    <Box  {...props} >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="#201F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3V2z"
      ></path>
    </svg>
  </Box>
  );
}

export default FacebookIcon;