import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";



export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dwerli-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div
    style={{
      display: 'flex'
    }}
    >
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="دورلى"
          style={{
            height: 33,
            width: 37
          }}
        />


         <h2
          style={{
            margin: 4,
            fontWeight: 700,
            fontSize: 20,
            color: 'black'
          }}
         >
         دوّر لي

         </h2>
   </div>
  );
};
