import React from "react";

function DollerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      fill="none"
      viewBox="0 0 12 20"
    >
      <path
        stroke="#201F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 1v18M10.167 4h-6.25c-.774 0-1.516.316-2.063.879A3.044 3.044 0 001 7c0 .796.307 1.559.854 2.121A2.876 2.876 0 003.917 10h4.166c.774 0 1.516.316 2.063.879.547.562.854 1.325.854 2.121s-.307 1.559-.854 2.121A2.876 2.876 0 018.083 16H1"
      ></path>
    </svg>
  );
}

export default DollerIcon;
