import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby-theme-material-ui";


const useStyles = makeStyles((theme) => ({
  link: {
    margin: 4,
    fontSize: 15,
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      color: "#F55739",
    },
  },
}));


export default (props) => {
  const classes = useStyles();

  return (
      <Link  {...props} className={classes.link}>
         {props.children}
      </Link>
  );
}
