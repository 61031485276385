import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui components
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// local components
import NavItemLink from '@atoms/NavItemLink';
// for reponsive
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
// nav data
import { navs } from "./navs.js"



/**
*
**/
function DesktopNav(props) {

  return (
  <Hidden smDown>
    <Box
     // props drilling
     { ...props}
     styles={{
       display: 'flex'
     }}
    >

      <Grid container>
        {navs.map(( nav, index ) => (
          <Grid item key={index}>
            <NavItemLink
              to={nav.to}
              title={nav.title}
            />
          </Grid>
        ))}
      </Grid>

    </Box>
  </Hidden>
  );
}


DesktopNav.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(DesktopNav);
