import React from "react";

function FolderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        stroke="#201F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.333 11.667A1.333 1.333 0 0113 13H2.333A1.334 1.334 0 011 11.667V2.333A1.333 1.333 0 012.333 1h3.334L7 3h6a1.333 1.333 0 011.333 1.333v7.334z"
      ></path>
    </svg>
  );
}

export default FolderIcon;