import React from "react";
import Link from "@atoms/Link"


const NavItemLink = ( props ) => {

  return(
    <Link
       to={props.to}
       style={{
          fontWeight: 'bold',
          fontSize: 15,
          padding: '16px',
       }}
     >
      {props.title}
    </Link>
  )
}

export default NavItemLink;
