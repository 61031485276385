import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  button: {
    padding: '11px 36px',
    borderRadius: 3,
    fontSize: '15px',
    boxShadow: '0 0 0',
    [theme.breakpoints.down('xs')]: {
      padding: 10
    },

  },
}));


export default (props) => {
  const classes = useStyles();

  return (
      <Button  {...props} className={classes.button}>
         {props.children}
      </Button>
  );
}
