/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px
* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from 'react';
// material-ui methods
import { makeStyles, withStyles } from '@material-ui/core/styles';
// material-ui components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// local components
import DesktopNav from "@molecules/Nav/DesktopNav"
import MobileNav from "@molecules/Nav/MobileNav"

import Logo from "@atoms/Logo"
import DwerliButton from "@atoms/Button"
// for reponsive
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';




/* create height order component */
const CustomAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 0px',
    minHeight: 73
  },
}))(AppBar);




/**
** @custom_style
**/
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
}));



/**
 * 
 * @returns 
 */
function Header() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CustomAppBar
        position="static"
       >

        <Toolbar
         disableGutters={true}
         style={{
             minHeight: 73
         }}
        >


          {/* site logo component */}
          <Box>
            <Logo />
          </Box>




          { /* HeaderNav ( hidden in mobile veiw) */ }
            <Box>
              <DesktopNav className={classes.menuButton} />
            </Box>



          {/* for flex desplay */}
          <Box className={classes.grow} />



          {/* action button group  ( hidden in mobile veiw )*/}

          <Hidden smDown>
              <DwerliButton variant="contained" color="secondary">
                اشتراك
              </ DwerliButton>

              <DwerliButton  color="primary">
                تسجيل الدخول
              </ DwerliButton>
          </Hidden>



          {/* open only in mobile veiw */}
          <MobileNav />


        </Toolbar>
      </CustomAppBar>
    </Box>
  );
}


Header.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Header);