/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

// material-ui components
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

// local components
import Header from "@organisms/Header"
import Footer from "@organisms/Footer"


const Layout = ({ children }) => {
  return (
    <Container style={{ backgroundColor :'rgba(251, 251, 251, 0.5)'}}>

      {/* app header */}
      <Header />



      {/* warrp component */}
      <Box component="main" >
        {children}
      </Box>


      {/* app Footer */}
      <Footer />


    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
