import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        stroke="#F55739"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11 1H3c-.53 0-1.04.158-1.414.44C1.21 1.72 1 2.101 1 2.5v9c0 .398.21.78.586 1.06.375.282.884.44 1.414.44h8M13 11l4-4-4-4M17 7H4"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
